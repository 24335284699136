import { isArray } from '@mechhive/helpers';
import { useDocumentEvent } from '@mechhive/react';
import { AnimatePresence, motion } from 'framer-motion';
import noScroll from 'no-scroll';
import { cloneElement, isValidElement, useEffect, type ReactElement } from 'react';
import type { ModalContent, ModalContentProps } from './ModalContent';
import type { ModalFooterProps } from './ModalFooter';
import type { ModalStore } from './ModalStore';
import type { ModalTitle, ModalTitleProps } from './ModalTitle';

export type CustomModalProps = Omit<ModalProps, 'children'>;

type ModalProps = {
  store: ModalStore;
  children: 
    undefined |
    ReactElement<typeof ModalTitle | typeof ModalContent> | 
    Array<ReactElement<typeof ModalTitle | typeof ModalContent>>;
  isLoading?: boolean;
}

export const Modal = ( { children, store, isLoading = false } : ModalProps ) => {
  const items = isArray( children ) ? children : [ children ];
  const Title = items.find ( t => t?.type?.['displayName'] === 'ModalTitle' );
  const Content = items.find(  t => t?.type?.['displayName'] === 'ModalContent' );
  const Footer = items.find( t => t?.type?.['displayName'] === 'ModalFooter' );

  const handleClose = () => {
    store.close();
  }

  useEffect( () => {
    if ( store.open === true ) {
      noScroll.on();
    } else {
      noScroll.off();
    }
  }, [ store.open ] );

  useDocumentEvent( 'keydown', ( e ) => {
    if ( e.key === 'Escape' ) {
      store.close();
    }
  } )

  return (
    <AnimatePresence>
      { store.open &&
        <>
          <motion.div 
            className={ 'fixed inset-0 z-50 backdrop-blur-xl' }
            onClick={ () => {
              store.close();
            } }
            transition={ {
              ease: 'easeIn',
              duration: 0.2
            } }
            initial={ {
              opacity: 0
            } }
            animate={ {
              opacity: 1
            } }
            exit={ {
              opacity: 0
            } }
          />
          <div className={ 'fixed inset-0 flex items-center justify-center z-[51] pointer-events-none' }>
            { isLoading &&
              <div 
                className={ 'opacity-0 md:mt-12' }  
                style={ {
                  animation: 'loadingIndicatorFadeIn 0.5s ease forwards',
                  animationDelay: '0.5s'
                } }>
                <img
                  src={ 'https://cdn.rewarble.com/ui/loading-circle.svg' }
                  className={ 'animate-spin relative z-10 w-6 h-6' }
                  alt={ '' } 
                />
              </div>
            }
            { !isLoading &&
              <motion.div 
                className={ 'fixed inset-0 lg:inset-auto z-[51] bg-[#05051E] lg:w-full lg:max-w-screen-md lg:border border-[#FFFFFF]/20 md:rounded-[10px] pointer-events-auto flex flex-col lg:max-h-[calc(100dvh-32px)] lg:h-fit' }
                transition={ {
                  ease: 'easeIn',
                  duration: 0.2
                } }
                initial={ {
                  opacity: 0,
                  translateY: 10,
                  scale: 0.98
                } }
                animate={ {
                  opacity: 1,
                  translateY: 0,
                  scale: 1
                } }
                exit={ {
                  opacity: 0,
                  translateY: 10,
                  scale: 0.98
                } }
              >
                { ( Title && isValidElement<ModalTitleProps>( Title ) ) &&
                cloneElement<ModalTitleProps>( Title, {
                  onClickClose: handleClose,
                  ...Title.props,
                } )
                }
                { ( Content && isValidElement<ModalContentProps>( Content ) ) &&
                cloneElement<ModalContentProps>( Content, {
                  ...Content.props
                } )
                }
                { ( Footer && isValidElement<ModalFooterProps>( Footer ) ) &&
                cloneElement<ModalFooterProps>( Footer, {
                  ...Footer.props,
                } )
                }
              </motion.div>
            }
          </div>
        </>
      }
    </AnimatePresence>
  )
}

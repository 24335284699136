import { ApiError } from '@mechhive/api';
import { Image, ImageFit, ImageFormat, ImageLoading } from '@mechhive/react';
import { createSeoAlternateLinks, createSeoCanonical, createSeoMeta, internalServerError, notFound } from '@mechhive/remix';
import { NavLink, useLoaderData } from '@remix-run/react';
import type { LoaderFunctionArgs, MetaFunction } from '@vercel/remix';
import { json } from '@vercel/remix';
import { Fragment, useRef } from 'react';
import { createRewarbleApi } from '~/api/rewarble.api.server';
import { createStoryblokApi } from '~/api/storyblok.api.server';
import type { StoryblokDoc } from '~/api/types/storyblok/blocks/StoryblokDoc';
import type { StoryblokPageIndex } from '~/api/types/storyblok/pages/StoryblokPageIndex';
import { Button, ButtonVariant } from '~/components/Button';
import { ButtonSecondary } from '~/components/buttons/ButtonSecondary';
import { BrandCard } from '~/components/content/BrandCard';
import { FAQ, FAQItem } from '~/components/content/FAQ';
import { HexagonIcon, HexagonIconSize } from '~/components/content/HexagonIcon';
import { IconInfoBlock } from '~/components/content/IconInfoBlock';
import { IconTextBlock } from '~/components/content/IconTextBlock';
import { InfoBlock } from '~/components/content/InfoBlock';
import { MarqueeScroller, MarqueeScrollerDirection } from '~/components/content/MarqueeScroller';
import { Review } from '~/components/content/Review';
import { TrustpilotBanner } from '~/components/trustpilot/TrustpilotBanner';
import { SubTitle } from '~/components/typograph/SubTitle';
import { Title } from '~/components/typograph/Title';
import { useLanguageServer } from '~/hooks/useLanguage.server';
import { useLocalePath } from '~/hooks/useLocalePath';
import { useRegionServer } from '~/hooks/useRegion.server';
import { META_LANGUAGES, SUPPORTED_LANGUAGES } from '~/locale';
import { resellers } from '~/resellers';
import type { RootLoaderData } from '~/root';
import { createMetaUrl } from '~/utils/createMetaUrl';
import { getMetaBaseUrl } from '~/utils/getMetaBaseUrl';

export const config = { runtime: 'edge' };

export const loader = async ( { request, params } : LoaderFunctionArgs ) => {
  const language = useLanguageServer( params );
  const region = useRegionServer( request );
  if ( !SUPPORTED_LANGUAGES.includes( language as any ) ) {
    throw notFound();
  }
  
  try {
    const rewarbleApi = createRewarbleApi( request );
    const storyblokApi = createStoryblokApi();

    const [ regionResponse, storyResponse ] = await Promise.all( [
      rewarbleApi.web.getRegionSafe( region ),
      storyblokApi.getStory<StoryblokPageIndex>( 'pages/index', language )
    ] );

    return json( {
      brands: regionResponse.data[0].brands
        .filter( b => !b.unavailableRegions?.includes( region ) )
        .filter( b => !b.unavailableLanguages?.includes( language ) )
        .filter( b => b.slug !== 'visa-premium-usd' && b.slug !== 'visa-inbanx-usd' ).slice( 0,12 ),
      story: storyResponse.data.story.content,
      resellers: {
        row1: resellers,
        row2: resellers.reverse()
      },
      reviews: {
        row1: regionResponse.data[0].reviews,
        row2: regionResponse.data[0].reviews.reverse()
      }
    } );
  } catch ( ex ) {
    if ( ex instanceof ApiError ) {
      throw ex.toResponse()
    }

    throw internalServerError();
  }
}

export const meta : MetaFunction<typeof loader, { root: RootLoaderData }> = ( { data, params, matches } ) => {
  const baseUrl = getMetaBaseUrl( matches );
  const url = createMetaUrl( {
    baseUrl,
    path: '',
    lang: params.lang
  } );

  return [
    ...createSeoMeta( {
      title: data?.story.metaFields.title,
      description: data?.story.metaFields.description,
      og: {
        title: data?.story.metaFields.title,
        description: data?.story.metaFields.description,
        url,
        image: 'https://cdn.rewarble.com/rewarble-og.jpg'
      }
    } ),
    createSeoCanonical( {
      url
    } ),
    ...createSeoAlternateLinks( {
      baseUrl,
      defaultLocale: 'en',
      path: '',
      locales: META_LANGUAGES.map( m => ( {
        locale: m.lang,
        pathPrefix: m.pathPrefix ?? ''
      } ) )
    } )
  ]
}

export default function Index() {
  const loaderData = useLoaderData<typeof loader>();
  const localePath = useLocalePath();
  const resellersRef  = useRef<HTMLDivElement>( null );

  const handleBuyButtonClick = () => {
    if ( resellersRef.current ) {
      const top = resellersRef.current.offsetTop;
      window.scrollTo( {
        top: top - 140,
        behavior: 'smooth'
      } )
    }
  }

  return (
    <div className={ 'container mx-auto px-4 flex flex-col gap-20 lg:gap-[90px] items-center' }>
      <div className={ 'flex flex-col gap-[60px] w-full lg:w-auto items-center' }>
        <div className={ 'lg:w-[920px] flex flex-col items-center gap-[60px]' }>
          <div className={ 'flex flex-col gap-2.5 items-center' }>
            <Title headingLevel={ 1 }>
              { loaderData.story.title }
            </Title>
            <SubTitle className={ 'text-center w-3/4' }>
              { loaderData.story.subTitle }
            </SubTitle>
          </div>
          <div className={ 'hidden lg:grid grid-cols-3 w-full gap-[40px]' }>
            { loaderData.story.infoBlocks.map( ( block, index ) => {
              return (
                <InfoBlock 
                  key={ index } 
                  iconName={ block.iconName }
                  title={ block.title }
                  content={ block.content }
                />
              )
            } )}
          </div>
        </div>
        <div className={ 'flex flex-col lg:flex-row gap-[30px] lg:gap-[34px] text-xl items-center justify-center w-full md:w-[430px]' }>
          <Button
            className={ 'flex-1 w-full lg:w-auto' }
            onClick={ handleBuyButtonClick }
            variant={ ButtonVariant.Secondary }
          >
            { loaderData.story.buyVoucherButton }
          </Button>
          <Button 
            className={ 'flex-1 w-full lg:w-auto' }
            to={ localePath( '/redeem' ) }
            prefetch='intent'
          >
            { loaderData.story.redeemVoucherButton }
          </Button>
        </div>
        <TrustpilotBanner className={ 'flex flex-col items-center -mt-4 -mb-12' } />
      </div>
      <div className={ 'flex flex-col gap-[60px] lg:gap-[42px] w-screen px-4 lg:w-full' }>
        <div className={ 'flex flex-col gap-10' }>
          <div className={ 'text-xl font-semibold lg:text-base text-center' }>{ loaderData.story.bestsellerTitle }</div>
          <div className='flex'> 
            <div className={ 'w-full grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-x-5 gap-y-[30px] sm:gap-x-8 md:gap-x-6 lg:gap-x-8 sm:gap-y-10' }>
              { loaderData.brands.map( ( brand ) => {
                return (
                  <NavLink
                    key={ brand.slug }
                    to={ localePath( `/brands/${brand.slug}` ) }
                    className={ 'w-full' }
                    prefetch='intent'
                    unstable_viewTransition
                  >
                    { ( { isTransitioning } ) => {
                      return (
                        <BrandCard 
                          brandSlug={ brand.slug }
                          title={ brand.name }
                          sizes={ [
                            { maxWidth: 768, width: 296 },
                            { maxWidth: 1024, width: 234 },
                            { width: 168 }
                          ] }
                          style={ {
                            viewTransitionName: isTransitioning ? 'brand-card' : undefined
                          } }
                        />
                      )
                    } }
                  </NavLink>
                )
              } )}
            </div>
          </div>
          <div className={ 'flex flex-col items-center' }>
            <Button
              variant={ ButtonVariant.Secondary }
              to={ localePath( '/brands' ) }
              prefetch='intent'
            >
              {loaderData.story.seeAllButton }
            </Button>
          </div>
        </div>
        <div className={ 'lg:pt-9 flex flex-col items-start' }>
          <div className={ 'flex flex-col gap-[20px] lg:gap-[30px] w-full' }>
            <HexagonIcon
              iconName={ loaderData.story.reloadIconName } />
            <div className={ 'flex flex-col gap-2.5 -mt-4' }>
              <Title headingLevel={ 2 }>{ loaderData.story.reloadTitle }</Title>
              <SubTitle>{ loaderData.story.reloadSubTitle }</SubTitle>
            </div>
          </div>
          <div className={ 'pt-[30px] md:pt-[100px] flex flex-col gap-[80px] lg:gap-40 md:px-8 w-full lg:w-full lg:px-0' }>
            <div className={ 'flex flex-col lg:flex-row items-center' }>
              { loaderData.story.reloadIconInfoBlocks.map( ( block, index ) => {
                return (
                  <Fragment key={ index }>
                    <IconInfoBlock 
                      iconName={ block.iconName }
                      title={ block.title }
                      content={ block.content }
                    />
                    <div className={ 'last:hidden w-px h-7 lg:w-12 lg:h-px shrink-0 bg-[#564AD7]' } />
                  </Fragment>
                )
              } ) }
            </div>
            <div
              ref={ resellersRef }
              className={ 'flex flex-col gap-16' }>
              <div className={ 'flex flex-col gap-2' }>
                <Title headingLevel={ 2 }>{ loaderData.story.partnersTitle }</Title>
                <SubTitle>{ loaderData.story.partnersSubTitle }</SubTitle>
              </div>
              <div className={ 'flex flex-col gap-5 w-full relative overflow-x-hidden py-2' }>
                <div className={ 'w-24 absolute inset-y-0 bg-gradient-to-r from-[#05051E] via-[#05051E]/20 to-[#05051E]/0 z-10 pointer-events-none' }></div>
                <div className={ 'w-24 absolute inset-y-0 bg-gradient-to-l from-[#05051E] via-[#05051E]/20 to-[#05051E]/0 z-10 right-0 pointer-events-none' }></div>
                <MarqueeScroller
                  duration={ 120 }
                  contentClassName={ 'gap-[50px]' }>
                  <div className={ 'flex flex-row items-center gap-[50px]' }>
                    {
                      loaderData.resellers.row1.map( ( reseller, index ) => {
                        return (
                          <a
                            className={ 'transition-all duration-200 hover:drop-shadow-[0px_0px_8px_rgba(90,125,251,1)]' }
                            key={ index }
                            href={ reseller.link }
                            target={ '_blank' }
                            rel={ reseller.follow === true ? 'dofollow noreferrer' : 'nofollow noreferrer' }>
                            <Image
                              provider='images'
                              src={ reseller.image }
                              formats={ [
                                ImageFormat.WebP,
                                ImageFormat.PNG
                              ] }
                              width={ 120 }
                              height={ 30 }
                              fit={ ImageFit.Outside }
                              sizes={ [{
                                width: 225,
                                height: 30
                              }] }
                              loading={ ImageLoading.Lazy }
                              dprVariants={ [1,3] }
                            />
                          </a>
                        )
                      } )
                    }
                  </div>
                </MarqueeScroller>
                <MarqueeScroller
                  duration={ 120 }
                  direction={ MarqueeScrollerDirection.Left }
                  contentClassName={ 'gap-[50px]' }>
                  <div className={ 'flex flex-row items-center gap-[50px]' }>
                    {
                      loaderData.resellers.row2.map( ( reseller, index ) => {
                        return (
                          <a
                            className={ 'transition-all duration-200 hover:drop-shadow-[0px_0px_8px_rgba(90,125,251,1)]' }
                            key={ index }
                            href={ reseller.link }
                            target={ '_blank' }
                            rel={ reseller.follow === true ? 'dofollow noreferrer' : 'nofollow noreferrer' }>
                            <Image
                              provider='images'
                              src={ reseller.image }
                              formats={ [
                                ImageFormat.WebP,
                                ImageFormat.PNG
                              ] }
                              fit={ ImageFit.Outside }
                              width={ 120 }
                              height={ 30 }
                              sizes={ [{
                                width: 225,
                                height: 30
                              }] }
                              loading={ ImageLoading.Lazy }
                              dprVariants={ [1,3] }
                            />
                          </a>
                        )
                      } )
                    }
                  </div>
                </MarqueeScroller>
              </div>
              <div className={ 'flex items-center justify-center' }>
                <ButtonSecondary to={ localePath( '/distributors' ) }>{ loaderData.story.seeAllResellers }</ButtonSecondary>
              </div>
            </div>
            <div className={ 'flex flex-col gap-[100px]' }>
              <div className={ 'flex flex-col gap-[70px]' }>
                <div className={ 'flex flex-col gap-[42px]' }>
                  <div className={ 'flex flex-col gap-2.5' }>
                    <Title headingLevel={ 2 }>{ loaderData.story.aboutUsTitle }</Title>
                    <SubTitle>{ loaderData.story.aboutUsSubTitle }</SubTitle>
                  </div>
                  <div className={ 'grid grid-cols-1 lg:grid-cols-3 gap-[50px] items-center justify-center' }>
                    { loaderData.story.aboutUsHexagonIcons.map( ( icon, index ) => {
                      return (
                        <HexagonIcon 
                          className={ 'flex-1' }
                          key={ index }
                          content={ icon.content }
                          title={ icon.title }
                          iconName={ icon.iconName }
                        />
                      )
                    } )}
                  </div>
                </div>
                <div className={ 'flex flex-col gap-6' }>
                  <div className={ 'text-center text-xl font-semibold p-2.5' }>{ loaderData.story.aboutUsStoryTitle }</div>
                  <div className={ 'grid grid-cols-1 lg:grid-cols-2 gap-x-[120px] gap-y-12' }>
                    { loaderData.story.aboutUsIconTextBlocks.map( ( block, index ) => {
                      return (
                        <IconTextBlock 
                          title={ block.title }
                          content={ block.content }
                          iconName={ block.iconName }
                          key={ index }
                        />
                      )
                    } )}
                  </div>
                </div>
                <div className={ 'grid grid-cols-1 lg:grid-cols-4 gap-[42px]' }>
                  { loaderData.story.partnersHexagonIcons.map( ( icon, index ) => {
                    return (
                      <HexagonIcon 
                        key={ index }
                        content={ icon.content }
                        title={ icon.title }
                        iconName={ icon.iconName }
                        size={ loaderData.story.partnersHexagonIcons.length >= 4 ? HexagonIconSize.Small : HexagonIconSize.Default }
                      />
                    )
                  } )}
                </div>
              </div>
            </div>
            <div className={ 'flex flex-col gap-16' }>
              <div className={ 'flex flex-col gap-2.5' }>
                <Title headingLevel={ 2 }>{ loaderData.story.reviewsTitle }</Title>
              </div>
              <div className={ 'container flex flex-col gap-8 relative overflow-hidden' }>
                <div className={ 'w-24 absolute inset-y-0 bg-gradient-to-r from-[#05051E] via-[#05051E]/20 to-[#05051E]/0 z-10' }></div>
                <div className={ 'w-24 absolute inset-y-0 bg-gradient-to-l from-[#05051E] via-[#05051E]/20 to-[#05051E]/0 z-10 right-0' }></div>
                <MarqueeScroller
                  contentClassName={ 'gap-8' }
                  duration={ 60 }>
                  <div className={ 'flex flex-row gap-8 mt-8' }>
                    { loaderData.reviews.row1.map( ( review, index ) => {
                      return (
                        <Review 
                          key={ index }
                          username={ review.name }
                          title={ review.title }
                          review={ review.message }
                        />
                      )
                    } )}
                  </div>
                </MarqueeScroller>
                <MarqueeScroller
                  contentClassName={ 'gap-8' }
                  direction={ MarqueeScrollerDirection.Left }
                  duration={ 60 }>
                  <div className={ 'flex flex-row gap-8 mt-8' }>
                    { loaderData.reviews.row2.map( ( review, index ) => {
                      return (
                        <Review 
                          key={ index }
                          username={ review.name }
                          title={ review.title }
                          review={ review.message }
                        />
                      )
                    } )}
                  </div>
                </MarqueeScroller>
              </div>
            </div>
            <FAQ className={ 'lg:px-32 w-full' }>
              { loaderData.story.faqItems.map( ( faqItem ) => {
                return (
                  <FAQItem 
                    key={ faqItem._uid }
                    title={ faqItem.title }
                    content={ faqItem.content as StoryblokDoc }
                  />
                )
              } )}
            </FAQ>
          </div>
        </div>
      </div>
    </div>
  );
}
